@use "../../assets/styles/mixins.scss" as *;

.headerContent {
  min-height: 72px;
  background-color: #ffff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 30px;

  @include breakpoint-up(md) {
    padding: 30px 50px;
  }
}

.optumContainer {
  justify-content: center;
  display: flex;
}

.logoContainer {
  padding-left: 25px;
}

.optumContainer {
  img {
    height: 70px;
    width: 140px;
  }
}
