@use "../../assets/styles/mixins.scss" as *;

.bannerContent {
  padding: 0;

  .bannerContainer {
    margin: 0;
    width: 100%;

    @include breakpoint-up(md) {
      flex-direction: row-reverse;
      margin-bottom: 80px;
    }
  }
}

.bannerImage {
  height: 250px;

  @include breakpoint-up(md) {
    height: unset;
    border-radius: 0 0 0 80px;
  }
}

.bannerText {
  padding-left: 20px;
  padding-right: 20px;

  @include breakpoint-up(md) {
    padding: 30px 50px;
    padding-bottom: 0;
  }

  h1 {
    line-height: 54px;
    margin-bottom: 12px;
  }

  .bannerSubTitle {
    color: black;
    margin-top: 0;
    font-size: 18px;
    font-weight: 200;

    @include breakpoint-up(lg) {
      font-size: 20px;
    }
  }

  .bannerContact {
    color: #cc2e38;
    font-weight: 200;

    @include breakpoint-up(md) {
      margin-bottom: 0;
    }
    @include breakpoint-up(lg) {
      font-size: 32px;
    }
  }
}
