.appContainer {
  background-color: #fff;
  display: flex;
  justify-content: center;
  cursor: default;
}

.app {
  width: 100%;
  max-width: 1440px;
  position: relative;
}
