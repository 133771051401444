import React from "react";
import cn from "classnames";

import style from "./style.scss";

export default ({}) => (
  <div
    className={style.servicesContent}
    role="region"
    aria-labelledby="supportService"
  >
    <h1 className={style.title} id="supportService">
      Getting support for yourself or loved ones is easy with Here4TN.
    </h1>
    <p>
      Go to{" "}
      <a
        target="_blank"
        href="https://www.here4tn.com/"
        aria-label="Go to Here4TN.com"
      >
        <strong>Here4TN.com</strong>
      </a>{" "}
      to learn more about:
    </p>
    <div className={style.servicesItemContainer}>
      <div className="row">
        <div
          className={cn([
            style.servicesItemWrapper,
            "col-sm-12 col-md-6 col-lg-4",
          ])}
        >
          <div className={style.servicesItem}>
            <div className={style.item}>
              <img
                alt="Conversation Icon"
                src="/assets/icon_counceling.webp"
                width={80}
                height={80}
              />
              <h2>Five counseling visits</h2>
              <p>
                in network at <br />
                no cost
              </p>
            </div>
          </div>
        </div>
        <div
          className={cn([
            style.servicesItemWrapper,
            "col-sm-12 col-md-6 col-lg-4",
          ])}
        >
          <div className={style.servicesItem}>
            <div className={cn([style.item, style.star])}>
              <img
                alt="Star Icon"
                src="/assets/icon_support.webp"
                width={80}
                height={80}
              />
              <h2>In-the-moment support</h2>
              <p>
                from a master's-level <br /> clinician
              </p>
            </div>
          </div>
        </div>
        <div
          className={cn([
            style.servicesItemWrapper,
            "col-sm-12 col-md-12 col-lg-4",
          ])}
        >
          <div className={cn(style.servicesItem)}>
            <div className={cn([style.item, style.virtualCare])}>
              <img
                alt="Virtual Care Icon"
                src="/assets/icon_media.webp"
                width={80}
                height={80}
              />
              <h2>Virtual visits</h2>
              <p>
                with a dedicated <br /> licensed therapist via <br /> the{" "}
                <b>Talkspace</b> app
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
