@use "../../assets/styles/mixins.scss" as *;

.footerContent {
  background-color: #ffff;
  padding: 60px 40px;
  font-weight: 300;
}

.footerWrapper {
  max-width: 1100px;
  margin: auto;
  color: #4b4d4f;
  font-size: 14px;

  p {
    margin: 20px 0;
  }
}

.footerLogoContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.optumContainer {
  padding-right: 25px;
  justify-content: center;
  display: flex;
}

.optumContainer {
  img {
    height: 30px;
    width: 100px;

    @include breakpoint-up(md) {
      height: 40px;
      width: 137px;
    }
  }
}

.logoContainer {
  img {
    height: 30px;
    width: 75px;

    @include breakpoint-up(md) {
      height: 40px;
      width: 93px;
    }
  }
}
