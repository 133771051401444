import React from "react";
import cn from "classnames";

import style from "./style.scss";

export default ({}) => (
  <div className={cn(style.headerContent)} role="banner">
    <div className={cn(style.optumContainer)}>
      <a
        href="https://www.here4tn.com/"
        target="_blank"
        aria-label="Go to Here4TN.com"
      >
        <img
          alt="4Here TN Support For Life Logo"
          src="/assets/Here4TN_logo.webp"
          height={70}
          width={140}
        />
      </a>
    </div>
  </div>
);
