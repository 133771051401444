import React from "react";
import cn from "classnames";

import Button from "../Button";

import style from "./style.scss";

export default ({}) => (
  <div
    className={style.supportContent}
    role="region"
    aria-labelledby="supportParents"
  >
    <div className={style.supportTitle} id="supportParents">
      <h1 className={style.supportTitleText}>
        Support for parents and caregivers
      </h1>
    </div>
    <div className={cn(style.supportWrapper)}>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className={cn(style.supportItem)}>
            <div
              className={cn(style.supportImage)}
              style={{
                backgroundImage: `url(/assets/image_talking-with-teens.webp)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
            <div className={cn(style.supportItemWrapper)}>
              <h2>Talking with teens and young adults about mental health </h2>
              <p>Get four tips for starting the conversation.</p>
              <Button
                buttonStyle={style.readMore1}
                text="Read now"
                onClick={() => {
                  window.open(
                    "https://www.here4tn.com/content/dam/cex-consumer/state-of-tn/documents/Here4TN-Teen-Mental-Health-Flyer.pdf",
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className={cn(style.supportItem)}>
            <div
              className={cn(style.supportImage)}
              style={{
                backgroundImage: `url(/assets/image_start-conversation.webp)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
            <div className={cn(style.supportItemWrapper)}>
              <h2>Start the conversation</h2>
              <p>
                Not sure how to get your teen talking or move the conversation
                past one-word answers? Try this digital deck of 30 conversations
                starters.
              </p>
              <Button
                text="Download for desktop"
                buttonStyle={style.supportButton}
                onClick={() => {
                  window.open(
                    "https://www.here4tn.com/content/dam/cex-consumer/state-of-tn/documents/Here4TN-Teen-Conversation-Starters-Desktop.pdf",
                    "_blank"
                  );
                }}
              />
              <Button
                text="Download for mobile"
                buttonStyle={style.supportButton}
                onClick={() => {
                  window.open(
                    "https://www.here4tn.com/content/dam/cex-consumer/state-of-tn/documents/Here4TN-Teen-Conversation-Starters-Mobile.pdf",
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className={cn(style.supportItem)}>
            <div
              className={cn(style.supportImage)}
              style={{
                backgroundImage: `url(/assets/img_Family_Support_Program.webp)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
            <div className={cn(style.supportItemWrapper)}>
              <h2>Family Support Program </h2>
              <p>
                Caring for a young person with behavioral health concerns such
                as autism, anxiety, depression or another issue? Get help
                navigating the often-confusing tangle of resources and
                stakeholders that can include schools, providers, public
                agencies and more.
              </p>
              <Button
                text="Learn more"
                onClick={() => {
                  window.open(
                    "https://www.liveandworkwell.com/?pin=Here4TN&redirectURL=/content/en/member/benefits/bh/family-support.html",
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className={cn(style.supportItem)}>
            <div
              className={cn(style.supportImage)}
              style={{
                backgroundImage: `url(/assets/img_Have_a_Child.webp)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
            <div className={cn(style.supportItemWrapper)}>
              <h2>Have a child who’s 18+?</h2>
              <p>
                Once kids turn 18, their parents no longer have access to
                medical records unless the young adult fills out a form to grant
                permission. If you'd benefit from being able to speak with your
                adult child's therapist or doctor, check out this form.
              </p>
              <Button
                text="View form"
                onClick={() => {
                  window.open(
                    "https://memberforms.optum.com/AuthorizationForReleaseOfHealthInformationForm.html",
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
