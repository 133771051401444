@use "../../assets/styles/mixins.scss" as *;

.supportContent {
  background-color: #f5f5f5;
  padding: 60px 20px;
}

.supportTitle {
  text-align: center;
}

.supportTitleText {
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
  margin-top: 0;
  margin-bottom: 0;
}

.supportItem {
  padding-top: 50px;
}

.supportImage {
  height: 315px;
}

.supportItemWrapper {
  h2 {
    color: #cc2e38;
    margin-bottom: 12px;
    font-size: 28px;
    font-weight: 900;

    @include breakpoint-up(lg) {
      margin-right: 30px;
    }
  }

  p {
    margin-top: 0;
    font-weight: 200;
    color: black;
    line-height: 24px;
    font-size: 18px;

    @include breakpoint-up(lg) {
      padding-right: 55px;
    }
  }
}

.supportButton {
  margin-top: 10px;
  margin-right: 10px;
}

.supportWrapper {
  max-width: 1100px;
  margin: auto;
}

.readMore1 {
  @include breakpoint-up(md) {
    margin-top: 23px;
  }
}
