@use "../../assets/styles/mixins.scss" as *;

.supportTeensContent {
  background-color: #022c6b;
  color: #ffff;
  padding-bottom: 60px;

  .selfCareWrapper {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .supportWrapper {
    margin: 0;
  }

  .supportImage {
    padding: 0;
  }
}

.selfCareImageWrapper {
  height: 250px;

  @include breakpoint-up(md) {
    height: 450px;
    border-radius: 0 0px 80px 0px;
  }
}

.useCode {
  font-size: 22px;
}

.selfCareText {
  text-align: center;
  max-width: 450px;
  margin: auto;

  @include breakpoint-up(md) {
    text-align: left;
    padding-left: 30px;
  }

  @include breakpoint-up(lg) {
    margin: 0;
  }

  h1 {
    font-size: 36px;
    @include breakpoint-up(lg) {
      padding-right: 100px;
    }
  }

  h2 {
    font-weight: 500;
  }

  h3 {
    font-weight: 300;
  }

  p {
    font-size: 18px;
    font-weight: 200;
  }
}

.storeContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint-up(md) {
    justify-content: flex-start;
  }

  img {
    padding: 0 5px;

    @include breakpoint-up(md) {
      padding-left: 0;
    }
  }
}

.boxWrapper {
  margin-top: 40px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.boxItem {
  margin: 8px auto;
  padding: 35px 56px 20px;
  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-align: center;
  max-width: 250px;

  h3 {
    color: #022c6b;
    text-align: center;
    font-size: 22px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.box1 {
  @include breakpoint-up(lg) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.link {
  color: #1c70cf;
  font-size: 18px;
  font-weight: 500;
}
