@use "../../assets/styles/mixins.scss" as *;

.button {
  cursor: pointer;
  color: #faf8f2;
  background-color: #002577;
  border-radius: 20px;
  border: 0;
  font-family: Myriad Pro, Arial, sans-serif;
  padding: 10px 35px;
  font-size: 14px;
  font-weight: 700;

  @include breakpoint-up(lg) {
    font-size: 16px;
  }
}
