import "./assets/styles/root.scss";

import React from "react";
import { createRoot } from "react-dom/client";
import cn from "classnames";

import Header from "./components/Header";
import Banner from "./components/Banner";
import Services from "./components/Services";
import Support from "./components/Support";
import SupportTeens from "./components/SupportTeens";
import Help from "./components/Help";
import Footer from "./components/Footer";

import style from "./style.scss";

const App = () => {
  return (
    <div className={cn(style.appContainer)}>
      <div className={cn(style.app)}>
        <Header />
        <div role="main">
          <Banner />
          <Services />
          <Support />
          <SupportTeens />
          <Help />
        </div>
        <Footer />
      </div>
    </div>
  );
};

const container = document.getElementById("main");
const root = createRoot(container);
root.render(<App />);
