import React from "react";
import cn from "classnames";

import style from "./style.scss";

export default ({}) => (
  <div
    className={style.bannerContent}
    role="region"
    aria-labelledby="supportBanner"
  >
    <div id="supportBanner" className={cn(["row", style.bannerContainer])}>
      <div
        className={cn([style.bannerImage, "col-sm-12 col-md-5 col-lg-6"])}
        style={{
          backgroundImage: `url(/assets/Acr21663217179648545125.webp)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <div className="col-sm-12 col-md-7 col-lg-6">
        <div className={cn(style.bannerText)}>
          <h1>
            Being a teen or young adult in today’s world is tough, on young
            people and those who love them.
          </h1>
          <p className={cn(style.bannerSubTitle)}>
            Get support for the difficult moments and tough conversations.
          </p>
          <p className={cn(style.bannerContact)}>
            Call <b>Here4TN</b> Emotional Wellbeing Solutions at{" "}
            <b>855-Here4TN</b> (855-437-3486).
          </p>
        </div>
      </div>
    </div>
  </div>
);
