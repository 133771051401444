import React from "react";
import cn from "classnames";

import style from "./style.scss";

export default ({}) => (
  <div className={style.footerContent} role="contentinfo">
    <div className={style.footerWrapper}>
      <div className={cn(style.footerLogoContainer)}>
        <div className={cn(style.optumContainer)}>
          <img
            alt="Optum Logo"
            src="/assets/Optum_Logo_ora_RGB.webp"
            height={40}
            width={137}
          />
        </div>
        <div className={cn(style.logoContainer)}>
          <img
            alt="Partners For Health Logo"
            src="/assets/partners-4health-logo.webp"
            height={40}
            width={93}
          />
        </div>
      </div>
      <p>
        <b>
          This program should not be used for emergency or urgent care needs. In
          an emergency, call 911 or go to the nearest emergency room.{" "}
        </b>
        This program is not a substitute for a doctor's or professional's care.
        Due to the potential for a confict of interest, legal consultation will
        not be provided on issues that may involve legal action against Optum or
        its affiliates, or any entity through which the caller is receiving
        these services directly or indirectly (e.g., employer or health plan).
        This program and its components may not be available in all states or
        for all group sizes and are subject to change. Coverage exclusions and
        limitations may apply.
      </p>
      <p>
        <b>
          To reach a trained crisis counselor, call the 988 Suicide & Crisis
          Lifeline (previously known as the National Suicide Prevention
          Lifeline) at 988 or 1-800-273-TALK (1-800-273-8255).
        </b>{" "}
        You can also text <b>988</b> or chat at <b>988lifeline.org.</b> The
        lifeline provides 24/7 free and confdential support.
      </p>
      <p>
        Optum is a registered trademark of Optum, Inc. in the U.S. and other
        jurisdictions. All other brand or product names are the property of
        their respective owners. Because we are continuously improving our
        products and services, Optum reserves the right to change specifcations
        without prior notice. Optum is an equal opportunity employer.
      </p>
      <p>© 2024 Optum, Inc. All rights reserved.</p>
    </div>
  </div>
);
